<template>
  <div class="widgetContainer loginSuccess widgetContainer--center widgetContainer--full-screen">
    <div class="widgetContainer__body">
      <LottieAnimation animation-name="success" reference="pull-funds-success" :loop="true" />
      <p class="title">
        {{ $t('loggedIn_title') }}
      </p>
      <p class="description">
        {{ $t('loggedIn_desc') }}
      </p>
      <div class="widgetContainer__footer--fixed">
        <el-button type="primary" class="el-button__brand brand" @click="next">
          {{ $t('next') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  name: 'LoginSuccess',
  components: {
    LottieAnimation
  },
  methods: {
    next() {
      window.analytics && window.analytics.track('proceedPersonal');
      this.$router.push('/kyc/personDetails');
    }
  }
};
</script>

<style lang="scss">
.loginSuccess {
  justify-content: center;
  text-align: center;
  padding: 0;

  .widgetContainer__body {
    justify-content: center;
    position: relative;
  }

  .icon {
    font-size: 75px;
    margin-bottom: 36px;

    .path1 {
      &:before {
        color: var(--color-green);
      }
    }
  }

  .title {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 16px;
  }

  .description {
    color: rgba(#3c3c43, 0.6);
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
  }
}
</style>
